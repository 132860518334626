import React, {useState, useEffect} from 'react';
import {navigate} from 'gatsby';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {generatePrice} from '~utils/com-helper';
import {formatDate, buildImageUrl, toVND} from '~utils/helper';
import ChevronLeft from '~components/icons/chevron-left';
import {get} from '~utils/api';
import * as styles from './styles.module.css';
import * as orderHistoryStyles from '../styles.module.css';
import {Tag} from 'antd';

const OrderDetail = ({orderId}) => {
  const [order, setOrder] = useState(null);

  const getOrderDetail = async () => {
    try {
      const res = await get(`/v1/order/${orderId}`);
      if (!res) return;
      setOrder(res);
    } catch (e) {}
  };

  const getTotalPrice = () => {
    const {totalPrice, deliveryFee, promotionPrice, isUseMembershipWallet} = order;
    return (!isUseMembershipWallet ? totalPrice - (promotionPrice ?? 0) : 0) + (deliveryFee ?? 0);
  };

  useEffect(() => {
    if (!orderId) return;
    getOrderDetail();
  }, [orderId]);

  return (
    <div>
      <p className='text-34'>
        <button aria-label='Back' onClick={() => navigate(`/account/order-history`)} className={styles.btnBack}>
          <ChevronLeft size='22' fill='black' />
        </button>
        #RENTZY{orderId}
      </p>
      {order && (
        <div className={styles.content}>
          <div>
            <div className={styles.contentItem}>
              <div className={styles.productsHeader}>
                <p>Tất cả sản phẩm {order.items[0]?.isSale ? 'mua' : 'thuê'}</p>
                {order.isUseMembershipWallet && (
                  <p className={styles.membership}>
                    <Tag color='blue'>Gói thành viên</Tag>
                  </p>
                )}
              </div>

              <hr className='my-20 bg-gray-semi' />
              <div>
                {order.items?.map(x => {
                  return (
                    <React.Fragment key={x.orderId}>
                      <div className={styles.product}>
                        <LazyLoadImage
                          src={buildImageUrl(x.imageUrl, '60x90')}
                          alt={x.productName}
                          title={x.productName}
                          width={90}
                          height={130}
                          className='mb-0'
                        />
                        <div className={styles.productDetail}>
                          <div className={styles.productName}>{x.productName}</div>
                          {order.isSale ? (
                            <div className={styles.price}>
                              Giá bán: {generatePrice(x.originalSalePrice / x.quantities, x.salePrice / x.quantities)}
                            </div>
                          ) : (
                            <div className={styles.price}>
                              Giá thuê:{' '}
                              {generatePrice(x.originalRentalPrice / x.quantities, x.rentalPrice / x.quantities)}
                            </div>
                          )}
                          <div className={orderHistoryStyles.tags}>
                            {x.attributes.map(at => {
                              return (
                                <React.Fragment key={`${x.orderId}-at-${at.attributeId}`}>
                                  {at.type === 'VALUE_TYPE_COLOR' ? (
                                    <span className={styles.tagColor}>
                                      <div style={{backgroundColor: at.value, border: '1px solid #d8d6d6'}} />
                                    </span>
                                  ) : (
                                    <span className={styles.tag}>{at.value}</span>
                                  )}
                                </React.Fragment>
                              );
                            })}
                            <span className={styles.tag}>{x.quantities} sản phẩm</span>
                          </div>
                        </div>
                      </div>
                      <hr className='my-20 bg-gray-semi' />
                    </React.Fragment>
                  );
                })}
                {!order.isSale && (
                  <>
                    <div>
                      Thuê từ {formatDate(order.dateFrom)} - {formatDate(order.dateTo)}
                    </div>
                    <hr className='my-20 bg-gray-semi' />
                  </>
                )}
              </div>
              <div className={styles.detailContent}>
                <div>Giá {order.isSale ? 'mua' : 'thuê'}</div>
                <div>{toVND(order.totalPrice)}</div>
              </div>
            </div>
            <div className='mt-20'>
              <button className='ant-btn-primary py-10 px-14 text-15' onClick={() => navigate('/home')}>
                Tiếp tục mua hàng
              </button>
            </div>
          </div>
          <div className={styles.contentItem}>
            {order.address && (
              <div className='text-13'>
                <div className={styles.detailTitle}>Giao tới</div>
                <div>
                  <div>
                    {order.address.name}
                    <span> | </span>
                    {order.address.mobileNo}
                  </div>
                  <div>
                    {order.address.street ? `${order.address.street}, ` : ''}
                    {order.address.ward}, {order.address.district}
                  </div>
                  <div>{order.address.city}</div>
                </div>
                <hr className='my-15 bg-gray-semi' />
              </div>
            )}
            <div className='text-13'>
              <div className={styles.detailTitle}>Phương thức vận chuyển</div>
              <div>{order.deliveryName}</div>
              <hr className='my-15 bg-gray-semi' />
            </div>
            <div className={styles.detailContent}>
              <div className={styles.detailTitle}>Giá {order.isSale ? 'mua' : 'thuê'}</div>
              <div>{toVND(order.totalPrice)}</div>
            </div>
            <div className={styles.detailContent}>
              <div className={styles.detailTitle}>Phí vận chuyển</div>
              <div>{toVND(order.deliveryFee ?? 0)}</div>
            </div>
            {order.isUseMembershipWallet && (
              <div className={styles.detailContent}>
                <div className={styles.detailTitle}>Gói thành viên</div>
                <div>-{toVND(order.totalPrice)}</div>
              </div>
            )}
            <div className={styles.detailContent}>
              <div className={styles.detailTitle}>Mã giảm giá</div>
              {order.promotionCode && (
                <Tag color='blue' style={{marginRight: 0}}>
                  {order.promotionCode}
                </Tag>
              )}
            </div>
            <div className={styles.detailContent}>
              <div className={styles.detailTitle}>Giảm giá</div>
              <div>{toVND(0 - (order.promotionPrice ?? 0))}</div>
            </div>
            <hr className='mt-20 mb-25 bg-gray-semi' />
            <div className={`${styles.detailContent} text-16 font-bold`}>
              <div>Tổng cộng</div>
              <div className='text-secondary'>{toVND(getTotalPrice())}</div>
            </div>
            <div className={styles.detailContent}>
              <div>Tổng tiền cọc</div>
              <div>{toVND(order.depositPrice ?? 0)}</div>
            </div>
            {order.totalReturn && (
              <div className={styles.detailContent}>
                <div>Tiền trả lại</div>
                <div className='text-secondary'>{toVND(order.totalReturn)}</div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetail;
