// extracted by mini-css-extract-plugin
export var btnBack = "styles-module--btnBack--mtVI9";
export var content = "styles-module--content--RCP3+";
export var contentItem = "styles-module--contentItem--c-nu9";
export var detailContent = "styles-module--detailContent--zuMY+";
export var detailTitle = "styles-module--detailTitle--YChGH";
export var membership = "styles-module--membership--as6ez";
export var price = "styles-module--price--G6ZSg";
export var product = "styles-module--product--rli-J";
export var productDetail = "styles-module--productDetail--ihxBC";
export var productName = "styles-module--productName--VARGK";
export var productsHeader = "styles-module--productsHeader--k3yzP";
export var tag = "styles-module--tag--bxfmD";
export var tagColor = "styles-module--tagColor--ZqZau";